$(function(){

	let halfScreen = $(window).height() / 2;

	let stages      = $(".tech-stages__item"),
		stageStyle  = getComputedStyle($(".tech-stages__item")[0]),
		stageHeight = parseInt(stageStyle.height),
		stageMargin = parseInt(stageStyle.marginBottom);

	$(".tech-stages").on("mousewheel", function(event){

		stages.each(function(index, element){
			
			let position     = element.getBoundingClientRect().top,
				topBorder    = position - stageMargin,
				bottomBorder = position + stageMargin;


			if((halfScreen > topBorder) && (halfScreen < bottomBorder))  {
				$(element).addClass("is-active");
			} 
		});
	});

	$(window).resize(function(){
		
		halfScreen = $(window).height() / 2;
	});
});