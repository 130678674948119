$(function(){

	$(".search").on("click", function(){

		$(this).addClass("is-active");
		$(this).find(".search__input input").focus();

		$("body").on("click", closeSearch);

		function closeSearch(event){

			const elClass = typeof event.target.className === 'string' 
			? event.target.className : event.target.className.baseVal;

			if (elClass.indexOf("search") === -1){

				let activeSearch = $(".search.is-active");

				activeSearch.removeClass("is-active");
				activeSearch.addClass("is-collapsing");

				activeSearch.on("animationend", function(){

					$(this).removeClass("is-collapsing");
					activeSearch.off("animationoff");
					$("body").off("click", closeSearch);					
				});
			}
		}
	});


});