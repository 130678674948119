$(document).ready(function(){
	
	$(".js-modal").on("click", function(event) {
		event.preventDefault();
		event.stopPropagation();		

		let clickData = $(this).data("modal"),
			modal     = $(".modal").filter(function(index, el){

			return $(this).data("modal") === clickData;
		});

		modalOpen(modal);
	});

	$(".js-modal-close").on("click", function(event){

		let modal = $(this).parents(".modal");

		modalClose(modal);
	});

	$(".js-modal-overlay-close").on("click", function(event){

		if(event.target === event.currentTarget) {

			let modal = $(this).find(".modal");

			modalClose(modal);	
		}
	});

	$(".js-modal-plain").on("click", function(event){

		event.preventDefault();
		event.stopPropagation();

		let clickTarget = $(event.target);

		let modal = clickTarget.parents(".modal");

		modal.on("animationend", modalPlainWrapper);
		modalClosePlain(modal);		

		function modalPlainWrapper() {

			let clickData = clickTarget.data("modal");
			
			let passModal = $(".modal").filter(function(index, el){

				return $(this).data("modal") === clickData;
			});

			modal.off("animationend", modalPlainWrapper);
			modalOpenPlain(passModal);
		}		
	});

	$(".js-notificate").on("click", function(event){

		event.preventDefault();
		event.stopPropagation();

		showNotification();
	});

	$(".js-notification-close").on("click", function(event){

		event.preventDefault();
		event.stopPropagation();

		closeNotification();
	});
	

	function modalOpen(modal) {

		$("body").addClass("is-fixed");
		$(".overlay").addClass("is-active");

		modal.addClass("is-showing");

		$("body").on("mousewheel", function(event){
			event.preventDefault();
		});

		modal.on("animationend", modalOpenEnd);

		function modalOpenEnd() {
			modal.removeClass("is-showing");
			modal.addClass("is-active");
			modal.off("animationend", modalOpenEnd);
		}		
	}

	function modalClose(modal) {

		let activeModalsMods = [
			"is-active",
			"is-showing",
			"is-fading-in",
			"is-fading-out"
		];

		modal = modal.filter(function(index, el){

			let length = activeModalsMods.length;

			for (let i = 0; i < length; i++) {

				return $(el).hasClass(activeModalsMods[i]);
			}
		});

		modal.addClass("is-hiding");
		modal.removeClass("is-active");

		$(".overlay").removeClass("is-active");
		$(".overlay").addClass("is-hiding");

		let animationTarget = modal.parents(".overlay") ? modal.parents(".overlay") : modal;

		animationTarget.on("animationend", modalCloseEnd);

		$("body").off("mousewheel");

		function modalCloseEnd() {
			modal.removeClass("is-hiding");
			$(".overlay").removeClass("is-hiding");
			$("body").removeClass("is-fixed");
			modal.off("animationend", modalCloseEnd);
		}
	}

	function modalClosePlain(modal) {

		modal.removeClass("is-active");
		modal.addClass("is-fading-out");

		modal.on("animationend", modalCloseEndPlain);

		function modalCloseEndPlain() {
			modal.removeClass("is-fading-out");
			modal.off("animationend", modalCloseEndPlain);
		}
	}

	function modalOpenPlain(modal) {

		modal.addClass("is-fading-in");

		modal.on("animationend", modalOpenEndPlain);

		function modalOpenEndPlain() {
			modal.removeClass("is-fading-in");
			modal.addClass("is-active");
			modal.off("animationend", modalOpenEndPlain);
		}
	}

	let notificationTimer;

	function showNotification(text, timeOut) {

		let notification = $(".js-notification");

		timeOut = timeOut || 2000;
		text    = text || "Спасибо";

		notification.children(".modal__alert").text(text);
		notification.addClass("is-showing");

		notification.on("animationend", notificationOn);

		clearTimeout(notificationTimer);

		notificationTimer = window.setTimeout(function(){
			closeNotification();
		}, timeOut);

		function notificationOn() {
			notification.addClass("is-active");
			notification.removeClass("is-showing");
			notification.off("animationend", notificationOn);
		}
	}

	function closeNotification() {

		clearTimeout(notificationTimer);

		let notification = $(".js-notification");

		notification.removeClass("is-active");
		notification.addClass("is-hiding");

		notification.on("animationend", notificationEnd);

		function notificationEnd() {

			notification.removeClass("is-hiding");
			notification.off("animationend", notificationEnd);
		}	
	}

});