$(document).ready(function(){

	$(".js-order-control").click(function(){

		$(this).toggleClass("is-active")

		$(this)
			.parent(".order")
			.children(".order__main")
			.toggleClass("is-active");
	});
});