$(document).ready(function(){
	if(document.getElementById("contacts-map") !== null) {
		ymaps.ready(init);
    }

    function init(){     
        new ymaps.Map("contacts-map", {
            center: [55.36, 83.75],
            zoom: 10,
            controls: []
        });
    }
});