$(function(){

	$(".good-carousel").owlCarousel({
		loop: true,
		margin: 32,
		autoWidth: true,
		nav: true,
		controlsClass: "good-carousel__controls",
		navContainerClass: "good-carousel__arrows",
		navClass: [
			"good-carousel__arrow good-carousel__arrow--left",
			"good-carousel__arrow good-carousel__arrow--right"
		],
		navText: [
			'<svg class="icon"><use xlink:href="img/sprite.svg#left-arrow"><use></svg>',
			'<svg class="icon"><use xlink:href="img/sprite.svg#right-arrow"><use></svg>'
		]
	});
});