$(function(){

	$(".js-product-info").on("click", function(){

		$(".product-info__control.is-active").removeClass("is-active");
		$(this).addClass("is-active");

		$(".product-info__box.is-active").removeClass("is-active");
		$(".product-info__box").eq($(this).index()).addClass("is-active");
	});
});