$(function(){

	$(".promo-slider").owlCarousel({
		items: 1,
		loop: true,
		nav: true,
		dotClass: "promo-slider__dot",
		dotsClass: "promo-slider__dots",
		controlsClass: "promo-slider__controls",
		navContainerClass: "promo-slider__arrows",
		navClass: [
			"promo-slider__arrow promo-slider__arrow--left",
			"promo-slider__arrow promo-slider__arrow--right"
		],
		navText: [
			'<svg class="icon"><use xlink:href="img/sprite.svg#left-arrow"><use></svg>',
			'<svg class="icon"><use xlink:href="img/sprite.svg#right-arrow"><use></svg>'
		],
		animateOut: "fadeOut"
	});
});