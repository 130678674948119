$(function(){

	let usual = {
		"language": {
			"noResults": function(){
				return "Ничего не найдено"
			}
		}
	},
	noSearch = {
		minimumResultsForSearch: Infinity
	};

	$(".select__box").select2(usual);

	$(".select__box--no-search").select2(
		Object.assign(usual, noSearch)
	);

});