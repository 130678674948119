$(document).ready(function(){

	const numberValidation = (event) => {
		const inputKey = event.originalEvent.key;

		const operationsCheck = /^[a,z,c,v]{1}$/.test(inputKey)
		&& event.ctrlKey 
		|| /^Arrow.*$/.test(inputKey) 
		|| inputKey === 'Backspace';
		const numberCheck = /^[0-9]{1}$/.test(inputKey);

		return operationsCheck || numberCheck;
	};
	
	$('.quantity__box').on('keydown', numberValidation);

	$(".quantity__box").each(function(index, element){

		if (parseInt($(this).text()) <= 1) {
			$(this).prev(".js-quantity-dec").addClass("is-disabled");
		}
	});


	$(".js-quantity-inc").click(function(){

		let quantityContainer = $(this).prev(".quantity__box");

		let quantity = parseInt(quantityContainer.val());

		if (quantity >= 1) {
			$(this).prev().prev(".js-quantity-dec").removeClass("is-disabled");
		}

		quantityContainer.val(quantity + 1);		
	});

	$(".js-quantity-dec").click(function(){

		if($(this).hasClass("is-disabled")) {
			return;
		}

		let quantityContainer = $(this).next(".quantity__box");

		let quantity = parseInt(quantityContainer.val());

		if(quantity <= 2) {
			$(this).addClass("is-disabled");
		}		

		quantityContainer.val(quantity - 1);

	});
	



});
