const alertInput = (input, text='Заполните это поле') => {
	const node = `<div class="alert input__alert">${text}</div>`;

	$(input).addClass('input--alert');
	$(input).append(node);
};

const alertInputRemove = (input) => {
	input.removeClass('input--alert');
	input.children('.alert').remove();
};